import { TCommonProps } from "./common";
import {
	collisionDetectionLowGravity,
	collisionDetectionNoGravity,
	collisionDetectionNormalGravity,
	editingSettings,
	orbitalMotion,
	pathfindingContinuous,
	pathfindingMixedChangingScreen,
	pathfindingObstacles,
	shapeGeneration,
	sinusoidalMotion,
  } from "../assets";

type TTechnical = {
	title: string;
	description: string[];
}

type TFeature = {
	title: string;
	description: string[];
	videos: string[];
}

export type TProject = {
	description: string[];
	technicals: TTechnical;
	features: TFeature[];
  } & Required<Pick<TCommonProps, "name">>;

export const projects: TProject[] = [
	{
	  name: "Simulation engine",
	  description: [
		"As a C# coding challenge I handcrafted a simplified 2D physics engine for simulating environmental forces on various shape objects.",
		"The engine and all modules were built by myself from the ground up, without utilising any third party library or existing codebase.",
		"The purpose was to create a solution that I could 100% own and have greater autonomy over for making improvements."
	  ],
	  technicals: {
		title: "Technicals",
		description: [
			"Multi-threaded Win32 application to separate background and foreground processing",
			"Real-time graphics rendering using the GDI+ Win32 API",
			"C# programming using .NET 8 as the target framework",
			"Modular design for interfacing with the simulation engine",
			"Asynchronous processing of runtime events for UI and backend updates",
			"Event-driven simulation editor to deliver a WYSIWYG experience",
			"JSON-backed settings profile configuration supporting all CRUD operations and more",
			"Rich testing suite with NUnit for end-to-end simulation test runs"
	  ]},
	  features: [
		{
			title: "Collision Detection",
			description: [
				"High precision collision detection with mesh aligned collision boundaries",
				"Continuous collision detection for predicting shape trajectory collisions",
				"Broad and narrow phase collision sweeping for optimal performance",
				"Support for multiple concave and convex shapes with translational and rotational transformations",
				"Simplified collision response calculations using direction and momentum, such as mass and velocity"
			],
			videos: [collisionDetectionNoGravity, collisionDetectionLowGravity, collisionDetectionNormalGravity]
		},
		{
			title: "Environmental forces",
			description: [
				"Simulation of non-linear motion using vector fields, e.g. orbital, sinusoidal or random motion",
				"Event-based motion with configurable force direction alternation on a timed interval",
				"Responsive vector fields which re-calculate on window resize"
			],
			videos: [sinusoidalMotion, orbitalMotion]
		},
		{
			title: "Pathfinding",
			description: [
				"Shape pathfinding around static and dynamic obstacles, using different strategies based on the A* algorithm",
				"Non-linear waypoint generation for generating non-linear navigation paths",
				"Responsive navigation mesh which re-calculates on window resize"
			],
			videos: [pathfindingContinuous, pathfindingMixedChangingScreen, pathfindingObstacles]
		},
		{
			title: "Supporting",
			description: [
				"Highly configurable modular simulations using JSON-backed settings profiles",
				"Shape and scene generation using parameterised criteria",
				"Support for both runtime and design time simulation updates",
				"Simulation speed controls for moderating the frame rate, and stepping through frames",
				"Verbose logging and monitoring for troubleshooting and diagnostics"
			],
			videos: [shapeGeneration, editingSettings]
		}
	  ]
	}
  ];
