
type TSection = {
	p: string;
	h2: string;
	content?: string[];
  };

type TConfig = {
	html: {
	  title: string;
	  fullName: string;
	  email: string;
	  copyrightYear: number;
	};
	hero: {
	  name: string;
	  p: string[];
	};
	sections: {
	  about: Required<TSection>;
	  skills: TSection;
	  experience: TSection;
	  testimonials: TSection;
	  projects: TSection;
	  contact: TSection;
	};
  };

export const config: TConfig = {
	html: {
	  title: "Portfolio of James Roome",
	  fullName: "James Roome",
	  email: "jamesroome@gmail.com",
	  copyrightYear: 2025
	},
	hero: {
	  name: "James Roome",
	  p: ["Senior Software Engineer", "making a difference through building innovative solutions"],
	},
	sections: {
	  about: {
		p: "Introduction",
		h2: "Overview.",
		content: [
			"Dedicated IT professional with 13+ years' experience working with on-premises and cloud-based ERP solutions to deliver high-quality outcomes to key stakeholders.",
			"Strong understanding of business processes, and excellent problem solving and communication skills. Hands-on experience at all stages of the Software Development Lifecycle, working in agile teams and highly collaborative work environments."
		]
	  },
	  skills: {
		p: "Talent I can offer",
		h2: "Skills."
	  },
	  experience: {
		p: "My journey so far",
		h2: "Experience.",
	  },
	  projects: {
		p: "My work",
		h2: "Projects."
	  },
	  testimonials: {
		p: "What others say",
		h2: "Testimonials.",
	  },
	  contact: {
		p: "",
		h2: ""
	  }
	},
  };
